<template>
  <div class="circular">
    <svg class="progress" :width="size" :height="size" :viewBox="viewBox">
      <circle
        class="circular-meter"
        :cx="center"
        :cy="center"
        :r="radius"
        stroke-width="12"
      />
      <circle
        class="circular-value"
        :cx="center"
        :cy="center"
        :r="radius"
        stroke-width="12"
        :style="{ strokeDashoffset: offset, strokeDasharray: circumference }"
      />
    </svg>
  </div>
</template>

<style lang="postcss" scoped>
.circular {
  flex-direction: column;
}

.circular {
  transform: rotate(-90deg);
}

.circular-meter,
.circular-value {
  fill: none;
}

.circular-meter {
  stroke: #ebf8ff;
}

.circular-value {
  stroke: #3182ce;
  stroke-linecap: round;
}
</style>

<script>
export default {
  name: 'SqrProgressCirular',
  props: {
    size: { type: Number, default: 120 },
    radius: { type: Number, default: 54 },
    value: { type: Number, default: 50 },
    total: { type: Number, default: 100.0 }
  },
  computed: {
    viewBox() {
      return [0, 0, this.size, this.size].join(' ');
    },
    center() {
      return Math.floor(this.size / 2);
    },
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    offset() {
      const progress = this.value / this.total;
      return this.circumference * (1 - progress);
    }
  }
};
</script>
