<template>
  <div class="mb-2">
    <label
      v-if="label"
      class="block text-sm font-medium leading-5 text-gray-700 mb-2"
      >{{ label }}</label
    >
    <div
      class="max-w-3xl flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 hover border-dashed rounded-md"
      :class="dropClass"
      @dragenter="dragEnter"
      @dragover="dragOver"
      @dragleave="dragLeave"
      @drop="drop"
    >
      <input
        class="hidden"
        type="file"
        name="file"
        ref="uploadInput"
        :multiple="false"
        @change="uploadFiles($event)"
        accept="accept"
        v-bind="$attrs"
      />
      <div class="text-center text-gray-600" v-if="!uploading && !value">
        <fa :icon="['far', icon || 'upload']" />
        <p class="mt-1 text-sm text-gray-600">
          <button
            type="button"
            class="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
            @click="selectFile()"
          >
            Upload a file
          </button>
          <span v-show="dndSupport">
            or drag and drop
          </span>
        </p>
        <p class="mt-1 text-xs text-gray-500" v-if="help">
          {{ help }}
        </p>
      </div>
      <div clas="" v-if="uploading">
        <sqr-progress-circular :value="bytesTransferred" :total="totalBytes" />
      </div>
      <div v-if="value">
        <slot name="value">
          <span class="text-sm text-medium">
            {{ value.originalName || value.name }}
          </span>
          <button
            type="button"
            class="pointer text-sm text-blue-500 hover:text-blue-400"
            @click="selectFile()"
          >
            change
          </button>
        </slot>
      </div>
    </div>
    <slot name="invalid" v-if="v && v.$error">
      <p class="mt-1 text-xs text-red-600" v-if="!v.required">
        {{ label }} is required.
      </p>
    </slot>
    <sqr-error-banner
      :error="uploadError"
      class="mt-2"
      @close="uploadError = null"
    />
  </div>
</template>

<script>
import { contains } from 'ramda';
import SqrInput from './SqrInput';

// import SqrButton from './SqrButton';
import SqrProgressCircular from './SqrProgressCircular';
import SqrErrorBanner from './SqrErrorBanner';

export default {
  name: 'SqrInputFile',
  components: { SqrProgressCircular, SqrErrorBanner },
  mixins: [SqrInput],
  model: { input: 'value', event: 'file-uploaded' },
  props: {
    value: { type: Object },
    prefix: { type: String, required: true },
    icon: { type: String },
    help: { help: String },
    maxSize: { type: Number },
    accept: { type: String },
  },
  computed: {
    dndSupport() {
      var div = document.createElement('div');
      return (
        ('FormData' in window &&
          'FileReader' in window &&
          'draggable' in div) ||
        ('ondragstart' in div && 'ondrop' in div)
      );
    },
    dropClass() {
      return {
        'border-solid': Boolean(this.value),
        'border-blue-300': this.dragging && !this.draggingOver,
        'border-green-300': this.draggingOver,
        'border-red-300': this.v && this.v.$error,
        'bg-green-50': this.draggingOver,
      };
    },
  },
  data() {
    return {
      dragging: false,
      draggingOver: false,

      uploading: false,
      uploadError: null,

      deleting: false,
      bytesTransferred: null,
      totalBytes: 0,
      uploadTask: '',

      fileNum: 0,
      filesCount: 0,
    };
  },
  mounted() {
    // window.addEventListener('dragstart', this.winDragStart, true);
    // window.addEventListener('dragenter', this.winDragEnter, true);
    // window.addEventListener('dragover', this.winDragOver, true);
    // window.addEventListener('dragend', this.winDragEnd, true);
    // window.addEventListener('dragleave', this.winDragLeave, true);
    // window.addEventListener('drop', this.winDrop, true);
  },
  beforeDestroy() {
    // window.removeEventListener('dragstart', this.winDragStart, true);
    // window.removeEventListener('dragenter', this.winDragEnter, true);
    // window.removeEventListener('dragover', this.winDragOver, true);
    // window.removeEventListener('dragend', this.winDragEnd, true);
    // window.removeEventListener('dragleave', this.winDragLeave, true);
    // window.removeEventListener('drop', this.winDrop, true);
  },
  methods: {
    selectFile() {
      this.$refs.uploadInput.click();
    },
    async uploadFiles(e) {
      this.uploading = true;
      let fileList = e.target.files || e.dataTransfer.files;
      this.fileNum = 1;
      this.filesCount = fileList.length;
      const uploads = Array.from(Array(fileList.length).keys()).map(async x => {
        const result = await this.upload(fileList[x]);
        this.fileNum = this.fileNum + 1;
        return result;
      });
      const results = await Promise.all(uploads);
      this.$emit('files-uploaded', results);
      this.fileNum = 0;
      this.filesCount = 0;
      this.uploading = false;
    },
    async upload(file) {
      try {
        if (this.maxSize && file.size > this.maxSize) {
          this.uploadError = 'file is too large';
          return;
        }
        const accepted = this.accept?.split(/\s*,\s*/) ?? [];
        const ext = '.' + /(?:\.([^.]+))?$/.exec(file.name)?.[1];
        if (!contains(file.type, accepted) && !contains(ext, accepted)) {
          this.uploadError = 'file type not accepted';
          return;
        }
        this.uploadError = null;
        this.fileName = file.name;
        // this.uploading = true;
        const uploadTask = this.$fb()
          .storage()
          .ref(this.prefix + file.name)
          .put(file);
        uploadTask.on('state_changed', sp => {
          this.bytesTransferred = sp.bytesTransferred;
          this.totalBytes = sp.totalBytes;
        });
        this.uploadTask = uploadTask;
        const uploaded = await uploadTask;
        // const downloadURL = await uploaded.ref.getDownloadURL();
        const result = {
          contentType: uploaded.metadata.contentType,
          fullPath: uploaded.metadata.fullPath,
          name: uploaded.metadata.name,
          originalName: file.name,
          size: uploaded.metadata.size,
        };
        this.$emit('file-uploaded', result, file);
        // this.uploading = false;
        return result;
      } catch (error) {
        this.uploadError = error;
        this.uploading = false;
        return Promise.reject(error);
      }
    },
    drop(event) {
      event.preventDefault();
      event.stopPropagation();
      this.uploadFiles(event);
      this.draggingOver = false;
      this.dragging = false;
    },
    dragEnter() {
      // console.log('dragEnter', event);
      this.draggingOver = true;
    },
    dragOver() {
      // console.log('dragEnter', event);
      this.draggingOver = true;
    },
    dragLeave() {
      // console.log('dragLeave', event);
      this.draggingOver = false;
    },
    winDragEnter() {
      // event.stopPropagation();
      event.preventDefault();
      this.dragging = true;
      // console.log('winDragEnter', event);
    },
    winDragStart(event) {
      // event.stopPropagation();
      event.preventDefault();
      this.dragging = true;
      // console.log('winDragStart', event);
    },
    winDragOver(event) {
      event.stopPropagation();
      event.preventDefault();
      this.dragging = true;
      // console.log('winDragOver', event);
    },
    winDragLeave(event) {
      // event.stopPropagation();
      event.preventDefault();
      if (event.screenX === 0 && event.screenY === 0) {
        this.dragging = false;
      }
      // console.log('winDragLeave', event);
    },
    winDragExit(event) {
      event.stopPropagation();
      event.preventDefault();
      this.dragging = false;
      // console.log('winDragExit', event);
    },
    winDragEnd(event) {
      event.stopPropagation();
      event.preventDefault();
      // console.log('winDragEnd', event);
    },
    winDrop(event) {
      // event.stopPropagation();
      event.preventDefault();
      this.dragging = false;
      // console.log('winDrop', event);
    },
  },
};
</script>
