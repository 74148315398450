<template>
  <div>
    <sqr-input-file />
  </div>
</template>

<script>
import SqrInputFile from '@/sqrd-ui/SqrInputFile';
export default {
  name: 'FileEdit',
  components: { SqrInputFile }
};
</script>
